
import {
  IonPage,
  IonContent,
  alertController,
  toastController,
  loadingController,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonSpinner,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { Analytics } from "../../../common/analytics";
import { useStore } from "vuex";

/**
 * services
 */
import services from "../../../services";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonSpinner,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const screenName = "PAGE-SETTING_REPORT";
    const analytics = new Analytics();
    const state = reactive({
      form: {
        content: "",
        type: "",
      },
      user: {
        loading: true,
        data: {},
      },
      contentLoading: true,
      showType: true,
    });

    const presentAlert = async (message: any, callback: any = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    const openToast = async (message: any) => {
      const toast = await toastController.create({
        message: message,
        duration: 2000,
      });
      return toast.present();
    };

    const fetch = async () => {
      await services.authUser().then(
        (response) => {
          const { data } = response;
          state.user.data = data;
          state.user.loading = false;
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          console.log(message);
          openToast(message);
        }
      );

      setTimeout(() => {
        state.contentLoading = false;
      }, 500);
    };

    const onSubmit = async () => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "문제 신고 등록 중입니다.",
      });
      await loading.present();
      const params = {
        content: state.form.content,
        type: state.form.type,
      };
      await services.reportAdd(params).then(
        (response) => {
          if (response.status == 200) {
            setTimeout(function () {
              loading.dismiss();
              presentAlert("문제 신고 작성완료 되었습니다", async () => {
                window.history.length > 1 ? router.go(-1) : router.push("/");
              });
            }, 1000);
          } else {
            setTimeout(function () {
              loading.dismiss();
            }, 1000);
          }
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }

          setTimeout(function () {
            loading.dismiss();
            presentAlert(message);
          }, 1000);
        }
      );
    };

    onMounted(() => {
      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
      if (store.getters["getreport"].tpye != "") {
        if (store.getters["getreport"].tpye == "post") {
          state.form.type = `부적절한 게시글`; // 구분 자동선택
          state.form.content = `${store.getters["getreport"].id}이 부적절한 게시글입니다.`;
        } else if (store.getters["getreport"].tpye == "comment") {
          state.form.type = `부적절한 댓글`; // 구분 자동선택
          state.form.content = `${store.getters["getreport"].id}이 부적절한 댓글입니다.`;
        } else if (store.getters["getreport"].tpye == "review") {
          state.form.type = `부적절한 댓글`; // 구분 자동선택
          state.form.content = `${store.getters["getreport"].id}이 부적절한 리뷰입니다.`;
        } else if (store.getters["getreport"].tpye == "user") {
          state.form.type = `부적절한 사용자`; // 구분 자동선택
          state.form.content = `${store.getters["getreport"].id}이 부적절한 사용자입니다.`;
        }
        // 구분 표시 안되게 ( 사용자가 변경못하게 )
        state.showType = false;
      }
    });
    return { state, onSubmit };
  },
});
